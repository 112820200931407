import "./App.css";

function App() {
  return (
    <div class="relative flex flex-col items-center justify-center min-h-screen bg-white bg-cover min-w-screen">
      <div class="flex flex-col flex-col-reverse items-center justify-center p-10 mx-auto lg:flex-row lg:max-w-6xl lg:p-0">
        <div class="relative w-full pr-10 rounded-lg cursor-pointer md:w-2/3 lg:w-1/2 group">
          <div class="relative rounded-md">
            <img
              src="https://cdn.devdojo.com/images/september2020/grow-together.png"
              class="z-10 object-cover w-full h-full"
              alt="logo"
            />
          </div>
        </div>
        <div class="container relative z-20 flex flex-col w-full px-5 pr-12 mb-16 text-2xl text-gray-700 lg:w-1/2 sm:px-0 md:px-10 sm:items-center lg:items-start lg:mb-0">
          <h1 class="relative z-20 font-sans text-4xl font-extrabold leading-none text-black sm:text-5xl xl:text-6xl sm:text-center lg:text-left">
            <span class="relative">
              <span class="absolute bottom-0 left-0 inline-block w-full h-4 mb-1 -ml-1 transform -skew-x-3 bg-yellow-200"></span>
              <span class="relative">Learn Together,</span>
            </span>
            <span class="relative block text-yellow-400">Grow Together.</span>
          </h1>
          <p class="relative z-20 block mt-6 text-base text-gray-500 xl:text-lg sm:text-center lg:text-left">
            Join with hundreds of students and professionals from 20+ different
            nations and thrive with us! We can all help each other, learn
            together, and grow together.
          </p>
          <div class="relative flex items-center mt-10">
            <a
              href="https://alc.austin.edu.my"
              target="_blank"
              rel="noreferrer"
              class="flex items-center self-start justify-center px-5 py-3 text-base font-medium leading-tight text-white transition duration-150 ease-in-out bg-blue-500 border border-transparent rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:border-blue-600 focus:shadow-outline-blue md:py-4 md:text-lg xl:text-xl md:px-10"
            >
              Language Center
            </a>
            <a
              href="https://atm.austin.edu.my"
              target="_blank"
              rel="noreferrer"
              class="flex items-center self-start justify-center px-5 py-3 ml-5 text-base font-medium leading-tight text-white transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-lg shadow hover:bg-yellow-600 focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow md:py-4 md:text-lg xl:text-xl md:px-10"
            >
              Training Center
            </a>
          </div>
          {/* <p class="mt-6 text-xs text-gray-500 rounded-full">
            Already a member?{" "}
            <a href="#_" class="font-medium text-yellow-500 underline">
              Login Here
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default App;
